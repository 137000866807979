import { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ChangeEvent } from 'react';

type FormikBridgeProps<V = string> = {
  name: string;
  value: V;
  onChange: (e: unknown | ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: unknown | ChangeEvent<HTMLInputElement>) => void;
  onError?: boolean;
};

export function buildFormikBridgeProps<V>(
  formikBag: FormikProps<any>, // tslint:disable-line:no-any
  fieldName: string,
): FormikBridgeProps<V> {
  return {
    name: fieldName,
    onChange: formikBag.handleChange(fieldName),
    onBlur: formikBag.handleBlur(fieldName),
    value: formikBag.values[fieldName],
    onError:
      formikBag.touched[fieldName] && !isEmpty(formikBag.errors[fieldName]),
  };
}
